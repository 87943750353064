<script setup lang="ts">
/**
 * nuxt app
 * ==================================================================
 */
const { $actor, $pwa } = useNuxtApp()

/**
 * snackbar refresh to update
 * ==================================================================
 */
const showSnackbar = ref(false)

function doToggleSnackbar (isOpen: boolean) {
  showSnackbar.value = isOpen
}

/**
 * route & router
 * ==================================================================
 */
const route = useRoute()
const router = useRouter()

/**
 * authentication
 * ==================================================================
 */
try {
  if (!route.meta.noAuthRequired) {
    await $actor.core.doLogin()
  }
} catch (error) {
  await router.push('/login')
}

/**
 * lifecycle hook
 * ==================================================================
 */
onMounted(() => {
  if ($pwa?.needRefresh) {
    showSnackbar.value = true
  }
})
</script>

<template>
  <NuxtLayout>
    <NuxtPwaManifest />
    <NuxtPage />
    <lazy-r-snackbar-refresh-to-update
      :show="showSnackbar"
      @toggle-refresh-snackbar="doToggleSnackbar"
    />
  </NuxtLayout>
</template>

<style lang="scss">
@use './assets/scss/settings';
</style>
